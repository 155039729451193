import { PostDataType } from "@/data/types";
import React, { FC } from "react";
import Badge from "@/components/Badge/Badge";
import { Category, Maybe, SubCategory } from "@/graphql/generated/schema";
import { Route } from "next";

export interface SubCategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  subcategories: SubCategory[];
  category: Maybe<Category> | undefined
}

const SubCategoryBadgeList: FC<SubCategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  subcategories,
  category
}) => {

  return (

    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {subcategories.map((item, index) => (
        <Badge
          className={itemClass}
          key={index}
          name={item?.subCategoryName}
          href={`/${category?.slug}/${item?.slug}` as Route}
        />
      ))}
    </div>
  );
};

export default SubCategoryBadgeList;
