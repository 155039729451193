"use client";
import React, { FC } from "react";

import MediaVideo from "./MediaVideo";
import PostTypeFeaturedIcon from "@/components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";

import { CategorySectionType, Post } from "@/graphql/generated/schema";
import { getImage } from "@/config/getImage";
import Image from "next/image";
import Link from "next/link";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface PostFeaturedMediaProps {
  className?: string;
  post: Post;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = "w-full h-full",
  post,
  isHover = false,
}) => {
  const { image, video } =
    post ?? {};

  const isPostMedia = () => post.category?.postSectionType === CategorySectionType.IsVideoSection ? "video" : '';
  // console.log(post.video);

  // const renderGallerySlider = () => {
  //   if (!galleryImgs) return null;
  //   return (
  //     <GallerySlider
  //       href={href}
  //       galleryImgs={galleryImgs}
  //       className="absolute inset-0 z-10"
  //       galleryClass="absolute inset-0"
  //       ratioClass="absolute inset-0"
  //     />
  //   );
  // };

  const renderContent = () => {
    //   // GALLERY
    //   if (postType === "gallery") {
    //     return renderGallerySlider();
    //   }

    //   // VIDEO
    if (post.category?.postSectionType === CategorySectionType.IsVideoSection && !!post.video && isHover) {
      return <MediaVideo isHover={isHover} videoUrl={post.video} />;
    }

    //   // AUDIO
    // if (postType === "audio" && !!audioUrl) {
    //   return <MediaAudio post={post} />;
    // }

    // ICON
    return isPostMedia() ? (
      <span className="absolute inset-0 flex items-center justify-center ">
        <PostTypeFeaturedIcon
          className="hover:scale-105 transform cursor-pointer transition-transform"
          postType={'video'}
        />
      </span>
    ) : null;
  };

  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      <Image
        alt="featured"
        fill
        className="object-cover"
        src={getImage(image?.url)}
        sizes="(max-width: 600px) 480px, 800px"
      />
      <Image
        alt="featured"
        fill
        className="object-cover"
        src={getImage(image?.url)} sizes="(max-width: 600px) 480px, 800px"
      />
      {renderContent()}

      <Link title={post?.postitle} aria-label={post?.postitle}
        href={formatUrlForNews(post)}
        className={`block absolute inset-0 `}
      />
    </div>
  );
};

export default PostFeaturedMedia;


// "use client";

// import React, { FC } from "react";
// import { PostDataType } from "@/data/types";
// import GallerySlider from "./GallerySlider";
// import MediaVideo from "./MediaVideo";
// import PostTypeFeaturedIcon from "@/components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
// import MediaAudio from "./MediaAudio";
// import Link from "next/link";
// import Image from "next/image";

// export interface PostFeaturedMediaProps {
//   className?: string;
//   post: PostDataType;
//   isHover?: boolean;
// }

// const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
//   className = "w-full h-full",
//   post,
//   isHover = false,
// }) => {
//   const { featuredImage, postType, videoUrl, galleryImgs, audioUrl, id, href } =
//     post;

//   const isPostMedia = () => postType === "video" || postType === "audio";

//   const renderGallerySlider = () => {
//     if (!galleryImgs) return null;
//     return (
//       <GallerySlider
//         href={href}
//         galleryImgs={galleryImgs}
//         className="absolute inset-0 z-10"
//         galleryClass="absolute inset-0"
//         ratioClass="absolute inset-0"
//       />
//     );
//   };

//   const renderContent = () => {
//     // GALLERY
//     if (postType === "gallery") {
//       return renderGallerySlider();
//     }

//     // VIDEO
//     if (postType === "video" && !!videoUrl && isHover) {
//       return <MediaVideo isHover videoUrl={videoUrl} />;
//     }

//     // AUDIO
//     if (postType === "audio" && !!audioUrl) {
//       return <MediaAudio post={post} />;
//     }

//     // ICON
//     return isPostMedia() ? (
//       <span className="absolute inset-0 flex items-center justify-center ">
//         <PostTypeFeaturedIcon
//           className="hover:scale-105 transform cursor-pointer transition-transform"
//           postType={postType}
//         />
//       </span>
//     ) : null;
//   };

//   return (
//     <div className={`nc-PostFeaturedMedia relative ${className}`}>
//       {postType !== "gallery" && (
//         <Image
//           alt="featured"
//           fill
//           className="object-cover"
//           src={featuredImage}
//           sizes="(max-width: 600px) 480px, 800px"
//         />
//       )}
//       {renderContent()}
//       {postType !== "gallery" && (
//         <Link
//           href={href}
//           className={`block absolute inset-0 ${
//             !postType || postType === "standard"
//               ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
//               : ""
//           }`}
//         />
//       )}
//     </div>
//   );
// };

// export default PostFeaturedMedia;
